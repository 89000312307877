import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import paymentMethodModel from './paymentMethodModel'
import paymentOptionModel from './paymentOptionModel'

const {
  fetchAll,
  fetchOne,
  create,
  update,
  updateAttribute,
  deleteOne,
} = useModelStoreModule(paymentMethodModel)

const {
  fetchAll: fetchAllOptions,
} = useModelStoreModule(paymentOptionModel)

export default {
  name: 'payment_methods',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchAll: (ctx, queryParams) => fetchAll(ctx, {
      with_suggestions: true, with_banks: true, with_bank_accounts: true, ...queryParams,
    }),
    fetchOne,
    create,
    update,
    updateAttribute,
    deleteOne,

    fetchOptions: (ctx, queryParams) => fetchAllOptions(ctx, queryParams),
  },
}

<template>
  <div>
    <payment-methods-list-loader
      v-if="isLoading"
    />
    <template
      v-else
    >
      <b-row class="match-height">
        <b-col
          md="4"
        >
          <current-plan-card :data="data.currentSubscription" />
        </b-col>
        <b-col
          md="8"
        >
          <current-subscription-card :data="data.currentSubscription" />
        </b-col>
        <b-col
          md="12"
        >
          <addons-list :items="data.addons" />
        </b-col>
      </b-row>

    </template>
  </div>
</template>

<script>
import { onUnmounted, ref } from 'vue'
import PaymentMethodsListLoader from '@/views/loaders/ShippingMethodsListLoader.vue'
import store from '@/store'
import subscriptionStoreModule from '@/views/models/subscriptions/subscriptionStoreModule'
import CurrentPlanCard from '@/views/models/subscriptions/view/CurrentPlanCard.vue'
import CurrentSubscriptionCard from '@/views/models/subscriptions/view/CurrentSubscriptionCard.vue'
import AddonsList from '@/views/models/subscriptions/view/AddonsList.vue'
import { BCol, BRow } from 'bootstrap-vue'

export default {
  components: {
    BRow,
    BCol,
    PaymentMethodsListLoader,
    // SFC
    CurrentPlanCard,
    CurrentSubscriptionCard,
    AddonsList,
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'subscriptions'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, subscriptionStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(true)
    const items = ref([])
    const suggestions = ref([])
    const cities = ref([])
    const settings = ref({})
    const banks = ref([])
    const bankAccounts = ref([])
    const data = ref({
      // currentSubscription: {
      //   plan: {
      //     name: 'Kasseb Basic',
      //     period_unit: 'M',
      //     period_amount: '6',
      //   },
      //   end_at: 'Dec 10, 2023',
      //   price: 15,
      //   is_upgradable: true,
      //   is_cancellable: true,
      //   status: 'ACTV',
      //   features: [
      //     'Lorem ipsum dolor sit amet',
      //     'Eget porttitor lorem',
      //     'Facilisis in pretium nisl aliquet',
      //     'Faucibus porta lacus fringilla vel',
      //     'Aenean sit amet erat nunc',
      //   ],
      //   alerts: [
      //     {
      //       type: 'warning',
      //       text: 'You plan about to expire',
      //     },
      //   ],
      //   periods: [
      //     {
      //       total: 30,
      //       used: 25,
      //       remaining: 5,
      //       unit: 'D',
      //       status: 'primary',
      //     },
      //   ],
      // },
      // addons: [
      //   {
      //     code: 'SMS',
      //     name: 'SMS Messages',
      //     description: 'Send messages to customers for mobile number verifications or marketing campaigns.',
      //     balance: [
      //       {
      //         tag: 'International SMS',
      //         type: 'percentage',
      //         total: 100,
      //         used: 100,
      //         remaining: 0,
      //         unit: 'Messages',
      //         status: 'danger',
      //       },
      //       {
      //         tag: 'Saudi Numbers SMS',
      //         type: 'percentage',
      //         total: 500,
      //         used: 500 - 125,
      //         remaining: 125,
      //         unit: 'Messages',
      //         status: 'warning',
      //       },
      //     ],
      //     isComingSoon: true,
      //     isActive: false,
      //   },
      //   {
      //     code: 'WHATSAPP_MESSAGES',
      //     name: 'Whatsapp Messages',
      //     description: 'Used fpr customer mobile number verifications and marketing campaigns.',
      //     balance: [
      //       {
      //         tag: null,
      //         type: 'percentage',
      //         total: 1000,
      //         used: 0,
      //         remaining: 1000,
      //         unit: 'Messages',
      //         status: 'primary',
      //       },
      //     ],
      //     isComingSoon: true,
      //     isActive: false,
      //   },
      //   {
      //     code: 'APP',
      //     name: 'Mobile Application',
      //     description: 'Deliver your store to customers mobile.',
      //     balance: [
      //       {
      //         tag: 'Android App',
      //         type: 'boolean',
      //         status: 'primary',
      //       },
      //       {
      //         tag: 'IOS App',
      //         type: 'boolean',
      //         status: 'primary',
      //       },
      //     ],
      //     isComingSoon: false,
      //     isActive: true,
      //   },
      // ],
    })

    const fetchItemsList = () => {
      // ctx.refs.preview.reset()
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/fetchOverview`)
        .then(response => {
          isLoading.value = false
          data.value = response.data.data
        })
    }
    fetchItemsList()

    return {
      isLoading,
      items,
      suggestions,
      cities,
      settings,
      banks,
      bankAccounts,
      data,

      // Actions
      fetchItemsList,
    }
  },
}
</script>

<style lang="scss">
</style>

export default {
  namespaced: true,
  name: 'payment_methods',

  translatedAttributes: [
    'name',
    'description',
    'terms',
  ],

  /**
   * Prepare item data for backend request
   * @param item
   * @returns Object
   */
  toArray(item) {
    const settings = {}
    if (item.settings && item.settings.legnth) {
      item.settings.forEach(setting => {
        settings[setting.id] = setting.value
      })
    }
    return {
      id: item.id,
      name_en: item.name.en,
      name_ar: item.name.ar,
      gateway_configs: item.gateway_configs,
      network_configs: item.network_configs,
      is_active: item.is_active,
      ...item,
      settings,
      fixed_fees: item.fixed_fees ? item.fixed_fees * 100 : item.fixed_fees,
      ratio_fees: item.ratio_fees,
    }
  },

  /**
   * Parse backend payload to item object
   * @param data
   * @param initialItemData
   * @returns Object
   */
  fromArray: (data, initialItemData = {}) => ({
    ...initialItemData,
    name: {
      en: data.name_en,
      ar: data.name_ar,
    },
    fixed_fees: data.fixed_fees ? data.fixed_fees / 100 : data.fixed_fees,
    ratio_fees: data.ratio_fees,
    gateway_configs: data.gateway_configs,
    network_configs: data.network_configs,
    settings: data.settings,
    is_active: data.isActive,
    provider: data.provider,
  }),

  resolveValidationErrors: errors => ({
    id: errors.id,
    name: {
      en: errors.name_en,
      ar: errors.name_ar,
    },
    notes: {
      en: errors.notes_en,
      ar: errors.notes_ar,
    },
    fixed_fees: errors.fixed_fees,
    ratio_fees: errors.ratio_fees,
    is_active: errors.is_active,
  }),
}

<template>
  <b-card
    v-if="data"
    class="card-app-design"
  >
    <b-card-title
      v-t="'modules.subscriptions._view.current_plan'"
      class="mt-1 mb-75"
    />
    <b-card-text class="font-small-2 mb-2">
      {{ data.plan.name }}
    </b-card-text>

    <!-- design group -->
    <div class="design-group">
      <h6
        v-t="'modules.subscriptions._view.feature'"
        class="section-label"
      />
      <ul
        class="font-medium-2 plan-features-list"
      >
        <li
          v-for="(feature, i) in features"
          :key="`plan_feature_${i}`"
        >{{ feature }}</li>
        <span
          v-if="extra"
        >
          + {{ extra }} {{ $t('modules.subscriptions._view.extra_features') }}
        </span>
      </ul>
    </div>

    <!-- button -->
    <b-button
      v-if="0"
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      v-t="'modules.subscriptions._view.see_all_plans'"
      variant="primary"
      block
      :to="{ name: 'plans' }"
    />
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardText, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

const FEATURES_LIMIT = 7
export default {
  components: {
    BCard,
    BButton,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    features() {
      return this.data.plan.features.length > FEATURES_LIMIT ? this.data.plan.features.splice(0, (FEATURES_LIMIT - 1)) : this.data.plan.features
    },
    extra() {
      return this.data.plan.features.length > FEATURES_LIMIT ? this.data.plan.features.length - (FEATURES_LIMIT - 1) : 0
    },
  },
}
</script>

<style>
.plan-features-list li {
  list-style: circle;
}
</style>

<template>
  <div class="ecommerce-application">

    <b-card
      class="checkout-items list-view"
    >
      <b-card-title
        v-t="'modules.subscriptions.addons.addons'"
        class="mt-1 mb-75"
      />
      <b-card-text
        v-t="'modules.subscriptions.addons.addons:description'"
        class="font-small-2 mb-2"
      />

      <addons-item
        v-for="item in items"
        :ref="`item_${item.code}`"
        :key="`item_${item.code}`"
        :item="item"
        :balance="item.balance"
        @click-edit="itemId => modelItemId=itemId"
        @item-update="$emit('item-updated')"
      />
    </b-card>
  </div>
</template>

<script>
import {
  VBToggle, VBModal, BCard, BCardTitle, BCardText,
} from 'bootstrap-vue'
import { ref } from 'vue'
import AddonsItem from './AddonsItem.vue'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardText,
    AddonsItem,
  },
  directives: {
    'b-toggle': VBToggle,
    'b-modal': VBModal,
  },
  props: {
    isLoading: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasActiveBankAccounts: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      required: false,
      default: Array,
    },
  },
  setup(props, ctx) {
    const isAddNewSidebarActive = ref(false)
    const modelItemId = ref(null)
    return {
      // Sidebar
      isAddNewSidebarActive,
      modelItemId,
    }
  },
}
</script>

<style lang="scss">
@import '~@core/scss/base/pages/shipping-management.scss';
</style>

// Notification
import store from '@/store'
import Vue from 'vue'
import { catchException, toast } from '@core/utils/utils'
import { t } from '@core/libs/i18n/utils'

export const DEFAULT_ON_APPROVE_CALLBACK = () => {

}
export const DEFAULT_ON_SUCCESS_CALLBACK = () => {
  toast('success', t('Deleted!'), t('Item(s) has been deleted.'), 'TrashIcon')
}
export const DEFAULT_ON_FAILURE_CALLBACK = () => {
  toast(
    'danger',
    t('message.operation_failed'),
    '',
    'AlertTriangleIcon',
  )
}
export const DEFAULT_ALWAYS_CALLBACK = () => {
  //
}

export default function useModelDelete(
  storeModuleName,
  useBatchDelete = true,
  onApprove = DEFAULT_ON_APPROVE_CALLBACK,
  onSuccess = DEFAULT_ON_SUCCESS_CALLBACK,
  onFailed = DEFAULT_ON_FAILURE_CALLBACK,
  always = DEFAULT_ALWAYS_CALLBACK,
  storeModuleAction = null,
) {
  const performDelete = items => {
    store
      .dispatch(`${storeModuleName}/${storeModuleAction || (useBatchDelete ? 'deleteMany' : 'deleteOne')}`, items)
      .then(response => {
        if (response.status === 204) {
          onSuccess()
        } else {
          onFailed()
        }
      })
      .catch(e => {
        catchException(e)
        onFailed()
      })
      .finally(() => {
        always()
      })
  }

  const deleteItems = itemsIds => {
    Vue.swal({
      title: t('Are you sure?'),
      text: t('You won\'t be able to revert this!'),
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: t('Cancel'),
      confirmButtonText: `${t('Yes, delete')} ${itemsIds.length > 1
        ? `${itemsIds.length} ${t('items')}!`
        : `${t('this item')}!`}`,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
    }).then(result => {
      if (result.value) {
        onApprove()
        performDelete(itemsIds)
      }
    })
  }

  const deleteItem = itemId => {
    deleteItems([itemId])
  }

  return {
    deleteItem,
    deleteItems,
  }
}

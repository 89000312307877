<template>
  <b-card
    class="ecommerce-card"
    no-body
  >
    <div class="item-img">
      <b-link>
        <b-img
          :src="CompaniesLogos[item.code]"
          :alt="`${item.name}`"
          class="p-50"
        />
      </b-link>
    </div>

    <!-- Details: Card Body -->
    <b-card-body>
      <div class="item-name">
        <h6 class="mb-0">
          <b-link class="text-body">
            <span>{{ item.name }} </span>
          </b-link>
        </h6>
        <span
          class="item-company"
        >{{ item.description }}</span>
      </div>
      <div class="mt-auto">
        <!--        <span-->
        <!--          v-if="item.hasActiveZonesInfo"-->
        <!--          class="text-secondary"-->
        <!--        >-->
        <!--          <material-icon-->
        <!--            icon="request_quote"-->
        <!--            size="20"-->
        <!--          />-->
        <!--          <span v-t="`modules.payment_methods._list.payment_rates`" />: {{ item.activeZonesCount }}-->
        <!--        </span>-->
        <!--        <div-->

        <!--          v-if="item.hasActiveZonesInfo"-->
        <!--          class="pt-1"-->
        <!--        >-->
        <!--          <span-->
        <!--            v-if="item.isCodAllowed"-->
        <!--            class="delivery-date text-success"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="CheckIcon"-->
        <!--              size="20"-->
        <!--            />-->
        <!--            <span v-t="`modules.payment_methods._list.cod_is_allowed`" />-->
        <!--          </span>-->
        <!--          <span-->
        <!--            v-else-->
        <!--            class="delivery-date text-secondary"-->
        <!--          >-->
        <!--            <feather-icon-->
        <!--              icon="XIcon"-->
        <!--              size="20"-->
        <!--            />-->
        <!--            <span v-t="`modules.payment_methods._list.cod_is_not_allowed`" />-->
        <!--          </span>-->
        <!--        </div>-->

        <div
          v-if="1"
          class="mt-auto"
        >

          <div
            v-for="(balance, index) in item.balance"
            :key="`addon_balance_${item.code}_${index}`"
            class="mt-1"
          >
            <div
              v-if="balance.type === 'percentage'"
              class="progress-wrapper"
            >
              <b-card-text class="mb-50 d-flex justify-content-between font-weight-bolder">
                <span>{{ balance.tag || 'Usage' }}</span>
                <span>{{ balance.used }} of {{ balance.total }}</span>
              </b-card-text>
              <b-progress
                :value="balance.used"
                :max="balance.total"
                :class="`progress-bar-${balance.status}`"
                :variant="balance.status"
              />
              <b-card-text class="mt-50">
                {{ balance.remaining }} {{ balance.unit }} remaining
              </b-card-text>
            </div>
            <div v-else>
              <h5 :class="`text-${balance.status} d-block`">
                <feather-icon
                  icon="CheckIcon"
                  class="mr-50"
                />
                <span>{{ balance.tag }}</span>
              </h5>
            </div>
          </div>
        </div>

        <!--        <div-->
        <!--          class="mt-50"-->
        <!--        >-->
        <!--          <h5 class="item-price text-secondary d-block">-->
        <!--            <span>{{$t('modules.payment_methods._list.transaction_charges')}}:</span>-->
        <!--            <span-->
        <!--              v-t="'modules.payment_methods._messages.no_additional_fees'"-->
        <!--            />-->
        <!--          </h5>-->
        <!--        </div>-->
      </div>
    </b-card-body>

    <!-- Product Options/Actions -->
    <div class="item-options text-center">
      <b-button
        v-if="item.isActive"
        v-b-modal.modal-add-new
        variant="primary"
        class="btn-cart move-cart"
        :disabled="isLoading"
        @click="$emit('click-edit', item.code)"
      >
        <feather-icon
          icon="PlusIcon"
          class="mr-50"
        />
        <span
          v-t="'modules.subscriptions.addons.add'"
          class="text-nowrap"
        />
      </b-button>
      <div
        class="item-wrapper"
      >
        <div
          class="item-cost"
        >
          <p
            class="card-text"
          >
            <b-badge
              v-if="item.isComingSoon"
              v-t="`Coming Soon`"
              pill
              size="5pt"
              variant="light-secondary"
            />
          </p>
          <p
            v-if="item.showUnavailableMessage"
            class="card-text"
          >
            <b-badge
              pill
              size="5pt"
              variant="light-secondary"
            >
              Unavailable
            </b-badge>
          </p>
        </div>
      </div>

    </div>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BLink, BImg, BButton, BBadge, BCardText, BProgress,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted, ref } from 'vue'
import useModelDelete, {
  DEFAULT_ALWAYS_CALLBACK,
  DEFAULT_ON_APPROVE_CALLBACK, DEFAULT_ON_FAILURE_CALLBACK,
  DEFAULT_ON_SUCCESS_CALLBACK,
} from '@/views/models/common/useModelDelete'
import paymentMethodStoreModule from '@/views/models/payment_methods/paymentMethodStoreModule'

const CompaniesLogos = {
  SMS: require('@/assets/images/icons/addons/sms-sm.png'),
  WHATSAPP_MESSAGES: require('@/assets/images/icons/addons/whatsapp-sm.png'),
  APP: require('@/assets/images/icons/addons/mobile_app-sm.png'),
}

export default {
  components: {
    BCard,
    BCardBody,
    BLink,
    BImg,
    BButton,
    BBadge,
    BCardText,
    BProgress,
  },
  emits: ['click-edit'],
  props: {
    item: {
      type: Object,
      default: Object,
    },
    warningAlert: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      previousStatus: false,
    }
  },
  setup(props, ctx) {
    const STORE_MODULE_NAME = 'payment_methods'

    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, paymentMethodStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
    })

    const isLoading = ref(false)

    const {
      deleteItem,
    } = useModelDelete(
      STORE_MODULE_NAME,
      false,
      () => {
        DEFAULT_ON_APPROVE_CALLBACK()
        isLoading.value = true
      },
      () => {
        DEFAULT_ON_SUCCESS_CALLBACK()
        ctx.emit('item-update')
      },
      () => {
        DEFAULT_ON_FAILURE_CALLBACK()
      },
      () => {
        DEFAULT_ALWAYS_CALLBACK()
        isLoading.value = false
      },
    )

    const previousStatus = ref(false)
    const onStatusUpdateSuccess = () => {
      const { item } = props
      previousStatus.value = item.is_active
      isLoading.value = false
    }
    const onStatusUpdateFailure = () => {
      const { item } = props
      item.is_active = previousStatus.value
      isLoading.value = false
    }
    const updateStatus = (itemId, newStatus) => {
      isLoading.value = true
      store.dispatch(`${STORE_MODULE_NAME}/updateAttribute`, { itemId, attribute: 'is_active', value: newStatus })
        .then(response => {
          onStatusUpdateSuccess()
        })
        .catch(() => {
          onStatusUpdateFailure()
        })
    }
    return {
      CompaniesLogos,
      isLoading,
      previousStatus,
      deleteItem,
      updateStatus,
    }
  },
}
</script>

<style lang="css">
[dir] .ecommerce-application .checkout-items .ecommerce-card .item-img img {
  width: 150px;
}
.ecommerce-card {
  border: 1px solid rgba(75, 70, 92, 0.12);
}
</style>

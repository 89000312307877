<template>
  <b-card
    v-if="data"
    class="card-app-design d-flex"
  >
    <div class="d-flex justify-content-between ">
      <b-card-title
        v-t="'modules.subscriptions._view.current_subscription'"
      />

      <span>
        <b-badge
          v-t="`subscription_statuses.${data.status}`"
          :variant="`light-${resolveSubscriptionStatusVariant(data.status)}`"
        />
      </span>
    </div>
    <b-card-text class="font-small-2 mb-2">
      {{ data.subtitle }}
    </b-card-text>

    <b-row class="match-height">
      <b-col md="6">

        <!-- design group -->
        <div class="design-group">
          <h6
            v-t="'modules.subscriptions._view.plan'"
            class="section-label"
          />
          <span
            class="mr-1"
          >
            {{ data.plan.name }}
          </span>
        </div>
        <!--/ design group -->

        <!-- design group -->
        <div class="design-group">
          <h6
            v-t="'modules.subscriptions._view.valid_till'"
            class="section-label"
          />
          <span
            class="mr-1"
          >
            {{ formatDate(data.end_at) }}
          </span>
        </div>
        <!--/ design group -->

        <!-- design group -->
        <div class="design-group">
          <h6
            v-t="'modules.subscriptions._view.price'"
            class="section-label"
          />
          <span
            v-if="data.price"
            class="mr-1"
          >
            {{ data.price }} / {{ data.plan.period_amount === 1 ? '' : data.plan.period_amount }} {{ $t(`periods.${data.plan.period_unit}`) }}
          </span>
          <span
            v-else
            class="mr-1"
          >
            {{ $t('Free') }}
          </span>
        </div>
        <!--/ design group -->

      </b-col>
      <b-col md="6">
        <b-alert
          v-for="(alert, i) in data.alerts"
          :key="`alert_${i}`"
          :variant="alert.type"
          class="my-auto"
          show
        >
          <div class="alert-body">
            <feather-icon
              class="mr-25"
              icon="TriangleIcon"
            />
            <span class="ml-25">{{ alert.text }}</span>
          </div>
        </b-alert>
        <div class="progress-wrapper my-auto">
          <div
            v-for="(period, i) in data.periods"
            :key="`subscription_period_${i}`"
            class="mt-1"
          >
            <b-card-text class="mb-50 d-flex justify-content-between font-weight-bolder">
              <span v-t="'modules.subscriptions._view.usage'" />
              <span v-if="period.used >= period.total">100%</span>
              <span v-else>{{ period.used }} {{ $t('of') }} {{ period.total }} {{ $t(`periods.${period.unit}s`) }}</span>
            </b-card-text>
            <b-progress
              :value="period.used"
              :max="period.total"
              :class="`progress-bar-${period.status_class}`"
              :variant="period.status_class"
            />
            <b-card-text class="mt-50">
              {{ period.remaining > 0 ? period.remaining : 0 }} {{ $t(`periods.${period.unit}s`) }} {{ $t('modules.subscriptions._view.remaining_in_this_period') }}.
            </b-card-text>
          </div>
        </div>
      </b-col>
    </b-row>
    <div
      class="d-flex mt-2"
    >
      <!-- button -->
      <b-button
        v-if="data.is_renewable"
        v-t="'modules.subscriptions.actions.renew'"
        variant="primary"
        class="mr-2"
        :disabled="isLoading"
        @click="onAddToCartClick(data.code)"
      />
      <b-button
        v-if="data.is_upgradable"
        v-t="'modules.subscriptions.actions.upgrade'"
        variant="outline-primary"
        class="mr-2"
        :to="{ name: 'plans' }"
      />
      <b-button
        v-if="false && data.is_cancellable"
        v-t="'modules.subscriptions.actions.cancel'"
        variant="outline-danger"
      />
    </div>
  </b-card>
</template>

<script>
import {
  BCard, BBadge, BCardTitle, BCardText, BButton, BProgress, BRow, BCol, BAlert,
} from 'bootstrap-vue'
import { resolveSubscriptionStatusVariant } from '@core/utils/constants'
import { formatDate } from '@core/utils/filter'
import store from '@/store'
import Router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import { toast } from '@core/utils/utils'
import subscriptionStoreModule from '@/views/models/subscriptions/subscriptionStoreModule'
import purchaseStoreModule from '@/views/models/purchases/purchaseStoreModule'
import { onMounted, onUnmounted, ref } from 'vue'

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BCardTitle,
    BCardText,
    BProgress,
    BRow,
    BCol,
    BAlert,
  },
  directives: {
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      resolveSubscriptionStatusVariant,
    }
  },
  methods: { formatDate },

  setup() {
    const SUBSCRIPTIONS_STORE_MODULE_NAME = 'subscriptions'
    const PURCHASES_STORE_MODULE_NAME = 'purchases'

    // Register module
    if (!store.hasModule(SUBSCRIPTIONS_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTIONS_STORE_MODULE_NAME, subscriptionStoreModule)
    if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
    onMounted(() => {
      if (!store.hasModule(SUBSCRIPTIONS_STORE_MODULE_NAME)) store.registerModule(SUBSCRIPTIONS_STORE_MODULE_NAME, subscriptionStoreModule)
      if (!store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.registerModule(PURCHASES_STORE_MODULE_NAME, purchaseStoreModule)
    })

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(SUBSCRIPTIONS_STORE_MODULE_NAME)) store.unregisterModule(SUBSCRIPTIONS_STORE_MODULE_NAME)
      if (store.hasModule(PURCHASES_STORE_MODULE_NAME)) store.unregisterModule(PURCHASES_STORE_MODULE_NAME)
    })

    const isLoading = ref(false)

    // TODO: Move to a common file as this method is duplicated
    const onAddToCartClick = purchasableCode => {
      if (isLoading.value) {
        return
      }
      isLoading.value = true
      store.dispatch(`${PURCHASES_STORE_MODULE_NAME}/updateAttribute`, {
        itemId: 'cart',
        attribute: 'items',
        value: [{
          id: purchasableCode,
          qty: 1,
        }],
      })
        .then(response => {
          Router.push({ name: 'purchases.checkout' })
        })
        .catch(e => {
          const { t } = useI18nUtils()
          const title = typeof (t) === 'function' ? t('message.operation_failed') : 'Error'
          console.error(e)
          toast('danger', title, '', 'AlertTriangleIcon')
        })
        .finally(() => {
          isLoading.value = false
        })
    }

    return {
      isLoading,
      onAddToCartClick,
    }
  },
}
</script>

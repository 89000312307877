import useModelStoreModule from '@/views/models/common/useModelStoreModule'
import subscriptionModel from './subscriptionModel'
import planModel from './planModel'

const {
  fetchAll,
  // store,
  // update,
} = useModelStoreModule(subscriptionModel)

const {
  fetchAll: fetchPlans,
} = useModelStoreModule(planModel)

export default {
  name: 'subscriptions',
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchOverview: fetchAll,
    // update,
    fetchPlans,
  },
}
